import qs from 'qs'

export const queryInterimHomePageBasic = (options: queryOptions) => {
  const query = qs.stringify(
    {
      locale: options?.locale || 'en-US',
      populate: {
        localizations: {
          fields: ['title', 'locale', 'id'],
          populate: {
            biennialEntry: {
              fields: ['id', 'editionIndex', 'title', 'locale'],
            }
          }
        },
        biennialEntry: {
          fields: ['id', 'editionIndex', 'title', 'locale'],
          populate: {
            colors: true,
          }
        }
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `interim-home-page?${query}`
}
