export const getParticipantIdPath = (id: string | number, slug: string) => {
  const route = useRoute()

  // NOTE this is temp fix: wait for resolve https://github.com/nuxt/nuxt/issues/23232
  const routeObject = {
    name: 'editions-slug-index-participants',
    params: { slug: slug },
    query: { ...route?.query, participantId: id }
  }
  // const routeObject = {
  //   name: 'editions-slug-index-participants-id',
  //   params: { slug: slug, id: id },
  //   query: route?.query
  // }

  return routeObject
}
