import type { RouterOptions } from '@nuxt/schema'

const scrollIfNeeded = (to, from, savedPosition) => {
  const getRouteBaseName = useRouteBaseName()

  if (to?.path === from?.path) { return }

  const fromName = getRouteBaseName(from) || ''
  const toName = getRouteBaseName(to) || ''

  const fromNameBase = fromName?.split('-')[0] || ''
  const toNameBase = toName?.split('-')[0] || ''

  // NOTE extend with new pages as needed
  const modalPages = [
    'editions-slug-index-education-child',
    'editions-slug-index-education-child',
    'editions-slug-index-participants-id',
    'index-education-slug',
    'index-education',
    'index-press',
    'index-slug',
    'index-urban-vision',
    'news-slug',
    'publications-slug',
  ]

  if (modalPages?.includes(toName) && fromNameBase === toNameBase ||
        modalPages?.includes(fromName) && fromNameBase === toNameBase ||
        modalPages?.includes(fromName) && toNameBase === 'index'
     ) { return }
  else {
    return { left: 0, top: 0 }
  }
}

export default <RouterOptions> {
  scrollBehavior (to, from, savedPosition) {
    const nuxtApp = useNuxtApp()
    return new Promise((resolve) => {
      nuxtApp.hooks.hookOnce('page:finish', () => {
        resolve({
          ...scrollIfNeeded(to, from, savedPosition)
        })
      })
    })
  }
}
