export const getPageQuery = (options: queryOptions = {}) => {
  const nuxtApp = useNuxtApp()
  const { $i18n } = nuxtApp
  const { locale, defaultLocale } = $i18n

  const { params, query } = useRoute()
  const { slug } = params
  const { id } = params
  const { status } = query

  return {
    locale: locale.value || defaultLocale,
    slug,
    id,
    status,
    ...options
  }
}
