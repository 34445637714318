import { defineStore } from 'pinia'

export const useMainStore = defineStore('main', {
  state: () => ({
    prevRoute: {},
    editionsNavOpen: false,
    singleTypeTitles: null,
    siteTitle: 'Manifesta',
    siteNavData: null,
  }),
  actions: {
    setPrevRoute (route: object) {
      this.prevRoute = {
        name: route?.name,
        path: route?.path
      }
    },
    setEditionsNavOpen (open: boolean) {
      this.editionsNavOpen = open
    }
  }
})
