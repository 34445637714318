import payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44 from "/src/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_7oiQEaGVD5lEdAymYsyhhHC8hduoBTxsRDeChwB2bxE from "/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_797e777a6d541011f9483323880488dc/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_EDQMo4lxD4p34ERBZJTbn2ATfL5Lo1ga68s_WQkfZEo from "/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_797e777a6d541011f9483323880488dc/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_oXkRfNc6L5tGXNjaQ788WxegEyLhH_Z3fepHcDRSfiM from "/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_797e777a6d541011f9483323880488dc/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A from "/src/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_07NfWRZKDPnLU3IyqhY5NE8k08O2TtZIGSrSYpj3YqU from "/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_797e777a6d541011f9483323880488dc/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_5vDpayUdKCs8EShGNmaUHYKpzDviKRGeNpCKie4flyE from "/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_797e777a6d541011f9483323880488dc/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_RIgBJJLjd0FvS9l4zj54MOwkjxJeOUdVIjcieaHt3U4 from "/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_797e777a6d541011f9483323880488dc/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_2ktw9icY4GR_u9M2roe8fv1T_4DjYBKJXCzxT70AWeg from "/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_797e777a6d541011f9483323880488dc/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo from "/src/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/src/.nuxt/components.plugin.mjs";
import prefetch_client_P5essQZTJyLE_8FYuTNTqZ8g4FSDB4XBsg_yxuETz7U from "/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_797e777a6d541011f9483323880488dc/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_iieHUJP83rz82LnOwnXFTO2vi9RmoA2wQ4AtmOjfJKY from "/src/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.37.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_b6tMprELJsbXO6Z_6MBZKwZMQU4u__NLPm_iQWcQF4E from "/src/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.37.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_kjx4ZSnsAqtm1n6jnAqkbg9iXs_m1LGbcdO47LBkXRM from "/src/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.37.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_SEGQMPD5At44s_Cn8uiy2HJ_Jl0WSNxFEqxmLYx1SHA from "/src/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.37.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import i18n_hC6A3DIanD8Hn4pRVH0UFAV16cB24l_P4EUpCBMwmZY from "/src/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import plugin_client_NwdnULXAwc_v6m7WVDkQFlGmJAIsKnEFEqOIwKwj3N4 from "/src/node_modules/.pnpm/nuxt-gtag@3.0.2_magicast@0.3.5/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_CTz1dCW0mIhOMd9CIA0WC1_x9wDBcROb22_wDKfPIYo from "/src/node_modules/.pnpm/nuxt-bugsnag@8.4.0_@bugsnag+core@8.2.0_magicast@0.3.5_vue-router@4.5.0_vue@3.5.13_typescript@5.8.2__/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import switch_locale_path_ssr_c_21y1ZI2Qfnc1B68NEIvI0x9bRgNzODQf5_v9ZVQW8 from "/src/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_8739fac6bfb843af9ca5122dc31e8d19/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_LRW0_JZuxRApTTiVwXHlytkx2G2vkcZoncTt7zSVs8M from "/src/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_8739fac6bfb843af9ca5122dc31e8d19/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_8hlE_qOvhFQNZZ2VOTpAFFSvexXDegwkyHDkpXH8BDM from "/src/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_8739fac6bfb843af9ca5122dc31e8d19/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import vue_tag_client_iop_2uZJGh9uwZ3Z0dmNgHZII58j_LgOthngMvNd_SQ from "/src/node_modules/.pnpm/@brambergvd+nuxt-base-layer@0.0.1-83_magicast@0.3.5_nuxt@3.16.1_@parcel+watcher@2.5.1_@_2db55764941a72e9c5a80e735e852a44/node_modules/@brambergvd/nuxt-base-layer/plugins/vue-tag.client.ts";
import directive_link_animated_mouse_events_r5ExMkoh4Jdnic6Eih3DzeHu70sc5h8VjBi7EhIPuOg from "/src/plugins/directive-link-animated-mouse-events.ts";
import link_participants_jMYtFc4Q4eCRTDeHhKIvzGIkkMnWv49HTJ68wK84fgY from "/src/plugins/link-participants.ts";
import router_S6yKjqoQ3Sb8o2lehB5PRjhy2uyi4DI_Ja_jhT1JS60 from "/src/plugins/router.ts";
import _0_routeRules_aRktwn18PtZcChfchq30dsWTAQGxYEEni1wLV71fvQ0 from "/src/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.37.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/0.routeRules.js";
import ssg_detect_b7YRCJRvuoEoBlu_pg6MIC0p5zcf5HrYGADTzRDMBq0 from "/src/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_8739fac6bfb843af9ca5122dc31e8d19/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44,
  revive_payload_client_7oiQEaGVD5lEdAymYsyhhHC8hduoBTxsRDeChwB2bxE,
  unhead_EDQMo4lxD4p34ERBZJTbn2ATfL5Lo1ga68s_WQkfZEo,
  router_oXkRfNc6L5tGXNjaQ788WxegEyLhH_Z3fepHcDRSfiM,
  _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A,
  payload_client_07NfWRZKDPnLU3IyqhY5NE8k08O2TtZIGSrSYpj3YqU,
  navigation_repaint_client_5vDpayUdKCs8EShGNmaUHYKpzDviKRGeNpCKie4flyE,
  check_outdated_build_client_RIgBJJLjd0FvS9l4zj54MOwkjxJeOUdVIjcieaHt3U4,
  chunk_reload_client_2ktw9icY4GR_u9M2roe8fv1T_4DjYBKJXCzxT70AWeg,
  plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_P5essQZTJyLE_8FYuTNTqZ8g4FSDB4XBsg_yxuETz7U,
  siteConfig_iieHUJP83rz82LnOwnXFTO2vi9RmoA2wQ4AtmOjfJKY,
  inferSeoMetaPlugin_b6tMprELJsbXO6Z_6MBZKwZMQU4u__NLPm_iQWcQF4E,
  titles_kjx4ZSnsAqtm1n6jnAqkbg9iXs_m1LGbcdO47LBkXRM,
  defaultsWaitI18n_SEGQMPD5At44s_Cn8uiy2HJ_Jl0WSNxFEqxmLYx1SHA,
  i18n_hC6A3DIanD8Hn4pRVH0UFAV16cB24l_P4EUpCBMwmZY,
  plugin_client_NwdnULXAwc_v6m7WVDkQFlGmJAIsKnEFEqOIwKwj3N4,
  plugin_CTz1dCW0mIhOMd9CIA0WC1_x9wDBcROb22_wDKfPIYo,
  switch_locale_path_ssr_c_21y1ZI2Qfnc1B68NEIvI0x9bRgNzODQf5_v9ZVQW8,
  route_locale_detect_LRW0_JZuxRApTTiVwXHlytkx2G2vkcZoncTt7zSVs8M,
  i18n_8hlE_qOvhFQNZZ2VOTpAFFSvexXDegwkyHDkpXH8BDM,
  vue_tag_client_iop_2uZJGh9uwZ3Z0dmNgHZII58j_LgOthngMvNd_SQ,
  directive_link_animated_mouse_events_r5ExMkoh4Jdnic6Eih3DzeHu70sc5h8VjBi7EhIPuOg,
  link_participants_jMYtFc4Q4eCRTDeHhKIvzGIkkMnWv49HTJ68wK84fgY,
  router_S6yKjqoQ3Sb8o2lehB5PRjhy2uyi4DI_Ja_jhT1JS60,
  _0_routeRules_aRktwn18PtZcChfchq30dsWTAQGxYEEni1wLV71fvQ0,
  ssg_detect_b7YRCJRvuoEoBlu_pg6MIC0p5zcf5HrYGADTzRDMBq0
]