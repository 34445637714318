import qs from 'qs'

const fields = [
  'slug',
  'locale'
]

const populate = {
  items: {
    on: queryFragmentLinks
  }
}

export const queryMenu = (options: queryOptions) => {
  const query = qs.stringify(
    {
      locale: options?.locale || 'en-US',
      filters: {
        slug: {
          $eq: options?.slug
        }
      },
      fields,
      populate: {
        ...populate,
        localizations: {
          fields,
        },
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `menu-entries?${query}`
}
