const fields = ['title', 'slug']
const localizationFields = ['title', 'slug', 'locale']

export const queryFragmentLinks = {
  'shared.link-single-type': {
    fields: ['page', 'label'],
  },
  'shared.link-external': {
    fields: ['label', 'url'],
  },
  'shared.link-collection-type': {
    fields: ['label'],
    populate: {
      biennialEntry: {
        fields: fields
      },
      educationEntry: {
        fields: fields,
        populate: {
          biennialEntry: {
            fields: fields
          },
        }
      },
      // NOTE ideally this should filter out interimWebsiteOnly items set to false on the main app. The useage for this is unlikely.
      newsEntry: {
        fields: fields
      },
      pageEntry: {
        fields: localizationFields,
        populate: {
          localizations: {
            fields: localizationFields,
          }
        }
      },
      publicationEntry: {
        fields: fields
      },
      urbanVisionEntry: {
        fields: ['title'],
        populate: {
          biennialEntry: {
            fields: fields
          },
        }
      },
    }
  }
}
