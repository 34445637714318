import qs from 'qs'

const fields = [
  'names',
  'collectiveName',
  'richText',
  'locale'
]

const populate = {
  biennialEntries: {
    fields: ['slug']
  },
  images: {
    fields: ['url']
  }
}

export const queryBiennialParticipantLinkThumbnail = (options: queryOptions) => {
  const query = qs.stringify(
    {
      locale: options?.locale || 'en-US',
      sort: ['id:asc'],
      pagination: {
        page: options?.page || 1,
        pageSize: options?.pageSize || 100
      },
      fields,
      populate: {
        ...populate,
        localizations: {
          fields,
        },
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `biennial-participant-entries?${query}`
}
