export const getCssColors = (options: any) => {
  const hasBackgroundColor = (options?.backgroundColor && options?.useBackgroundColor)
  const hasPrimaryColor = (options?.primaryColor && options?.usePrimaryColor)
  const hasSecondaryColor = (options?.secondaryColor && options?.useSecondaryColor)

  const backgroundColor = hasBackgroundColor ? options?.backgroundColor : 'var(--color--background--default)'
  const primaryColor = hasPrimaryColor ? options?.primaryColor : 'var(--color--primary--default)'
  const secondaryColor = hasSecondaryColor ? options?.secondaryColor : 'var(--color--secondary--default)'

  return {
    '--color--background': backgroundColor,
    '--color--primary': primaryColor,
    '--color--secondary': secondaryColor,
    '--color--text-decoration': secondaryColor,
  }
}
