<template>
  <div :class="$style.root">
    <code class="font-size-default" v-if="isDev">{{ error }}</code>
    <div
      :class="$style.error"
      v-else
    >
      <span :class="$style.message">{{ $t('error.pageNotFound') }}</span>&thinsp;
      <button
        @click="handleError"
        :class="$style.button"
      >
        {{ $t('error.button') }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
const runtimeConfig = useRuntimeConfig()

const props = defineProps({
  error: Object
})

const handleError = () => clearError({ redirect: '/' })

const isDev = runtimeConfig?.public?.env === 'development'
</script>

<style module>
.root {
  display: flex;
  flex-direction: column;
  justify-content: center;

  min-height: var(--100svh);
}

.error {
  composes: container font-size-large from global;
  text-align: center;
}

.message {
  color: var(--color--secondary);
}

.button {
  composes: reset-button link from global;
  text-transform: lowercase;
}
</style>
