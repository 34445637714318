<template>
  <div id="app">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
  const nuxtApp = useNuxtApp()
  const { $i18n } = nuxtApp
  const { locale } = $i18n
  const runtimeConfig = useRuntimeConfig()
  const mainStore = useMainStore()
  const interimStore = useInterimStore()

  // 1
  const { data: singleTypeData } = await useAsyncData(async () => {
    return await $fetch('/api/strapi-rest', { query: { path: `single-type-titles?locale=${locale.value}` } } )
  }, { watch: [locale] })

  watch(singleTypeData, (data) => {
    mainStore.singleTypeTitles = data
  }, { immediate: true })

  // 2
  const { data: siteNavData } = await useAsyncData(async () => {
    const slug = getAppVariant() === 'main' ? 'site-nav' : 'interim-site-nav'
    return await $fetch('/api/strapi-rest', { query: { path: queryMenu(getPageQuery({slug: slug})) } })
  }, { watch: [locale] })

  watch(siteNavData, (data) => {
    mainStore.siteNavData = getFirstObject(data?.data)
  }, { immediate: true })

  // 3
  if (getAppVariant() === 'interim') {
    const { data: interimHomePageBasicData } = await useAsyncData(async () => {
      const slug = getAppVariant() === 'main' ? 'site-nav' : 'interim-site-nav'
      return await $fetch('/api/strapi-rest', { query: { path: queryInterimHomePageBasic(getPageQuery()) } })
    }, { watch: [locale] })

    // const colors = getFirstObject(toRaw(interimHomePageBasicData.value))?.biennialEntry?.colors

    // useHead({
    //   htmlAttrs: {
    //     style: colors ? getCssColors(colors) : null
    //   }
    // })

    watch(interimHomePageBasicData, (data) => {
      const biennialEntry = getFirstObject(toRaw(data))?.biennialEntry
      const localizations = getFirstObject(toRaw(data))?.localizations

      if (!biennialEntry) return

      const editionIdDefaultLocale = biennialEntry?.locale === 'en-US' ? biennialEntry?.id : getFirstObject(toRaw(data))?.localizations?.find( (localization) => {
        return localization.locale === 'en-US'
      }
      )?.biennialEntry?.id

      interimStore.editionId = biennialEntry?.id
      interimStore.editionIdDefaultLocale = editionIdDefaultLocale
      interimStore.editionIndex = biennialEntry?.editionIndex
      interimStore.cssColorString = biennialEntry?.colors ? getCssColors(biennialEntry?.colors) : null
      if (biennialEntry?.title) {
        mainStore.siteTitle = biennialEntry?.title
      }
    }, { immediate: true })
  }

  // 4
  useHead({
    script: [
      {
        src: 'https://unpkg.com/large-small-dynamic-viewport-units-polyfill@0.0.4/dist/large-small-dynamic-viewport-units-polyfill.min.js'
      }
    ],
    link: [
      { rel: 'apple-touch-icon', sizes: '18', href: `${runtimeConfig?.public?.baseUrl}/favicon/dark/apple-touch-icon.png`, media: "(prefers-color-scheme: dark)" },
      { rel: 'icon', type: 'image/png', sizes: '32x32', href: `${runtimeConfig?.public?.baseUrl}/favicon/dark/favicon-32x32.png`, media: "(prefers-color-scheme: dark)" },
      { rel: 'icon', type: 'image/png', sizes: '16x16', href: `${runtimeConfig?.public?.baseUrl}/favicon/dark/favicon-16x16.png`, media: "(prefers-color-scheme: dark)" },
      { rel: 'apple-touch-icon', sizes: '18', href: `${runtimeConfig?.public?.baseUrl}/favicon/light/apple-touch-icon.png`, media: "(prefers-color-scheme: light)" },
      { rel: 'icon', type: 'image/png', sizes: '32x32', href: `${runtimeConfig?.public?.baseUrl}/favicon/light/favicon-32x32.png`, media: "(prefers-color-scheme: light)" },
      { rel: 'icon', type: 'image/png', sizes: '16x16', href: `${runtimeConfig?.public?.baseUrl}/favicon/light/favicon-16x16.png`, media: "(prefers-color-scheme: light)" },
    ]
  })
</script>
