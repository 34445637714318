export default defineNuxtPlugin((nuxtApp) => {
  const setDataActiveAttributeOnChildren = (el: HTMLElement, active: boolean) => {
    el.querySelectorAll('[class*="animated-underline"]').forEach((child) => {
      if (child?.hasAttribute('data-ignore')) return
      if (active) {
        child.setAttribute('data-active', null)
      } else {
        child.removeAttribute('data-active')
      }
    })
  }

  nuxtApp.vueApp.directive('animated-underline-mouse-events', {
    mounted: (el) => {

      el.addEventListener('mouseenter', () => {
        setDataActiveAttributeOnChildren(el, true)
      })

      el.addEventListener('mouseleave', () => {
        setDataActiveAttributeOnChildren(el, false)
      })
    },
    beforeUnmount: (el) => {
      el.removeEventListener('mouseenter', setDataActiveAttributeOnChildren, { passive: true })
      el.removeEventListener('mouseleave', setDataActiveAttributeOnChildren, { passive: true })
    }
  })
})
