import { defineStore } from 'pinia'

export const useInterimStore = defineStore('interimStore', {
  state: () => ({
    editionId: null,
    editionIdDefaultLocale: null,
    editionIndex: null,
    cssColorString: null
  })
})
