
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91slug_93C7Zp5zkZOYZ_gBvVQRBaAJapT4FTqxcDiPCdyJWEZ_45cMeta } from "/src/pages/main/news/[slug].vue?macro=true";
import { default as news7EROiutCbaOSxU2E7DI1081wyJFddOxR2IfvYx9DFbMMeta } from "/src/pages/main/news.vue?macro=true";
import { default as pressTfJMP3JFx36lIqXIOrWbTVQzu9WfpY_45R9qcOeiMJ7bsMeta } from "/src/pages/main/index/press.vue?macro=true";
import { default as _91slug_93orGmpEmJdUpynGbAluH3AGpHIetgYvq94RngHKpqe5sMeta } from "/src/pages/main/index/[slug].vue?macro=true";
import { default as _91slug_9329vZFFG7n7h5SqcY331rNO1MLCEdycqqagaVqGPMYJ0Meta } from "/src/pages/main/index/education/[slug].vue?macro=true";
import { default as educationCov60qNyYkl0ThSaaGp5_7hpTqFRwE0o_WcRTOULBPAMeta } from "/src/pages/main/index/education.vue?macro=true";
import { default as urban_45visionQRGAj7U_nutIXRFdkcLBOrqIkD9NOW4aqaXzbcuE6lUMeta } from "/src/pages/main/index/urban-vision.vue?macro=true";
import { default as indexDWyfg3v4bEa7fdfQBHddvOCmc4_45TKlJim_45VrfGwlB_kMeta } from "/src/pages/main/index.vue?macro=true";
import { default as newsletterglKpgmKI9dXWStl9tgMIvzzssDaU50hK0ymVpW_45iplwMeta } from "/src/pages/main/newsletter.vue?macro=true";
import { default as page_45previewqrLH_45DQwJ9oeI_45JZbQ5TbbUxP8DOzL7KAnSA8DyQkIUMeta } from "/src/pages/main/page-preview.vue?macro=true";
import { default as _91slug_93aI_45WTbpRJFJ5jW5CO48cVEdwZGSr9_qggISrgzJX3VcMeta } from "/src/pages/main/publications/[slug].vue?macro=true";
import { default as publicationsWli9YA8W8YOhMjcAvsTJoxEJbq1lUr9Tsfbv_458eAfnkMeta } from "/src/pages/main/publications.vue?macro=true";
import { default as indexZUsuO9EHI1jauLEeDnEaubR4qC9xBwwVRyLdOcW6VpMMeta } from "/src/pages/main/editions/index.vue?macro=true";
import { default as about2Kktb_leKuNiqjCWZG8CfoBUhf58cMbj6q9EOFW6ujsMeta } from "/src/pages/main/editions/[slug]/index/about.vue?macro=true";
import { default as insightsUsGO_UMPtRsXBte_wY_45B24Q7a70uOqVglJgUHGhpe50Meta } from "/src/pages/main/editions/[slug]/index/insights.vue?macro=true";
import { default as education7V2Sv_6BL7WKcNshWxJD_45TQ1_Bw4L_nmfisCZpU1mncMeta } from "/src/pages/main/editions/[slug]/index/education.vue?macro=true";
import { default as participants896Hhu_8dHgqEajf9GuZVGC1Q65ms_lJChlfByGJ5PAMeta } from "/src/pages/main/editions/[slug]/index/participants.vue?macro=true";
import { default as urban_45visionJupolXCW_45yHhZkdrTDjPzRZBixrAlt8_45_45jm4b_45mInDsMeta } from "/src/pages/main/editions/[slug]/index/urban-vision.vue?macro=true";
import { default as indexNLvw5kAcCyEoznsuBulwxwLjBpClr4B6_45iumenXPGJYMeta } from "/src/pages/main/editions/[slug]/index.vue?macro=true";
import { default as component_45stubqGbcNBpJDiQxuMGXNMmpAFr08rubCubpItFF_4511S_roMeta } from "/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_797e777a6d541011f9483323880488dc/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubqGbcNBpJDiQxuMGXNMmpAFr08rubCubpItFF_4511S_ro } from "/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_797e777a6d541011f9483323880488dc/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "news___en-US",
    path: "/news",
    component: () => import("/src/pages/main/news.vue"),
    children: [
  {
    name: "news-slug___en-US",
    path: ":slug()",
    component: () => import("/src/pages/main/news/[slug].vue")
  }
]
  },
  {
    name: "index___en-US",
    path: "/",
    component: () => import("/src/pages/main/index.vue"),
    children: [
  {
    name: "index-press___en-US",
    path: "press",
    component: () => import("/src/pages/main/index/press.vue")
  },
  {
    name: "index-slug___en-US",
    path: ":slug()",
    component: () => import("/src/pages/main/index/[slug].vue")
  },
  {
    name: "index-education___en-US",
    path: "education",
    component: () => import("/src/pages/main/index/education.vue"),
    children: [
  {
    name: "index-education-slug___en-US",
    path: ":slug()",
    component: () => import("/src/pages/main/index/education/[slug].vue")
  }
]
  },
  {
    name: "index-urban-vision___en-US",
    path: "urban-vision",
    component: () => import("/src/pages/main/index/urban-vision.vue")
  }
]
  },
  {
    name: "newsletter___en-US",
    path: "/newsletter",
    component: () => import("/src/pages/main/newsletter.vue")
  },
  {
    name: "page-preview___en-US",
    path: "/page-preview",
    component: () => import("/src/pages/main/page-preview.vue")
  },
  {
    name: "publications___en-US",
    path: "/publications",
    component: () => import("/src/pages/main/publications.vue"),
    children: [
  {
    name: "publications-slug___en-US",
    path: ":slug()",
    component: () => import("/src/pages/main/publications/[slug].vue")
  }
]
  },
  {
    name: "editions___en-US",
    path: "/editions",
    component: () => import("/src/pages/main/editions/index.vue")
  },
  {
    name: "editions-slug___en-US",
    path: "/editions/:slug()",
    meta: indexNLvw5kAcCyEoznsuBulwxwLjBpClr4B6_45iumenXPGJYMeta || {},
    component: () => import("/src/pages/main/editions/[slug]/index.vue"),
    children: [
  {
    name: "editions-slug-index-about___en-US",
    path: "about",
    component: () => import("/src/pages/main/editions/[slug]/index/about.vue")
  },
  {
    name: "editions-slug-index-insights___en-US",
    path: "insights",
    component: () => import("/src/pages/main/editions/[slug]/index/insights.vue")
  },
  {
    name: "editions-slug-index-education___en-US",
    path: "education",
    component: () => import("/src/pages/main/editions/[slug]/index/education.vue")
  },
  {
    name: "editions-slug-index-participants___en-US",
    path: "participants",
    component: () => import("/src/pages/main/editions/[slug]/index/participants.vue")
  },
  {
    name: "editions-slug-index-urban-vision___en-US",
    path: "urban-vision",
    component: () => import("/src/pages/main/editions/[slug]/index/urban-vision.vue")
  }
]
  },
  {
    name: component_45stubqGbcNBpJDiQxuMGXNMmpAFr08rubCubpItFF_4511S_roMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubqGbcNBpJDiQxuMGXNMmpAFr08rubCubpItFF_4511S_ro
  }
]