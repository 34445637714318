export const getFirstObject = (item: object) => {
  if (!item) { return }
  if (item instanceof Object === false) { return }

  let firstFilledType = null
  for (const [key, value] of Object.entries(item)) {
    if (value instanceof Object) {
      firstFilledType = {
        uid: key,
        ...value
      }
      break
    }
  }

  return firstFilledType
}
