import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoArrow, LazySvgoCross, LazySvgoLine, LazySvgoLogo, LazySvgoMinus, LazySvgoPlus, LazySvgoSearch, LazySvgoTriangle, LazyMagicAccordionContent, LazyMagicAccordionProvider, LazyMagicAccordionTrigger, LazyMagicAccordionView, LazyMagicCommandContent, LazyMagicCommandDrawer, LazyMagicCommandItem, LazyMagicCommandModal, LazyMagicCommandProvider, LazyMagicCommandRenderer, LazyMagicCommandTrigger, LazyMagicCommandView, LazyMagicCookieItem, LazyMagicCookieProvider, LazyMagicCookieView, LazyMagicDrawer, LazyMagicMarquee, LazyMagicMenuChannel, LazyMagicMenuContent, LazyMagicMenuFloat, LazyMagicMenuItem, LazyMagicMenuProvider, LazyMagicMenuRemote, LazyMagicMenuTrigger, LazyMagicMenuView, LazyMagicModal, LazyMagicPlayerAudio, LazyMagicPlayerAudioControls, LazyMagicPlayerDisplayTime, LazyMagicPlayerMuxPopover, LazyMagicPlayerOverlay, LazyMagicPlayerPoster, LazyMagicPlayerProvider, LazyMagicPlayerTimeline, LazyMagicPlayerVideo, LazyMagicPlayerVideoControls, LazyMagicScrollCollision, LazyMagicScrollMotion, LazyMagicScrollProvider, LazyMagicScrollScene, LazyMagicDraggable, LazyMagicNoise, LazyMagicToastProvider } from '#components'
const lazyGlobalComponents = [
  ["SvgoArrow", LazySvgoArrow],
["SvgoCross", LazySvgoCross],
["SvgoLine", LazySvgoLine],
["SvgoLogo", LazySvgoLogo],
["SvgoMinus", LazySvgoMinus],
["SvgoPlus", LazySvgoPlus],
["SvgoSearch", LazySvgoSearch],
["SvgoTriangle", LazySvgoTriangle],
["MagicAccordionContent", LazyMagicAccordionContent],
["MagicAccordionProvider", LazyMagicAccordionProvider],
["MagicAccordionTrigger", LazyMagicAccordionTrigger],
["MagicAccordionView", LazyMagicAccordionView],
["MagicCommandContent", LazyMagicCommandContent],
["MagicCommandDrawer", LazyMagicCommandDrawer],
["MagicCommandItem", LazyMagicCommandItem],
["MagicCommandModal", LazyMagicCommandModal],
["MagicCommandProvider", LazyMagicCommandProvider],
["MagicCommandRenderer", LazyMagicCommandRenderer],
["MagicCommandTrigger", LazyMagicCommandTrigger],
["MagicCommandView", LazyMagicCommandView],
["MagicCookieItem", LazyMagicCookieItem],
["MagicCookieProvider", LazyMagicCookieProvider],
["MagicCookieView", LazyMagicCookieView],
["MagicDrawer", LazyMagicDrawer],
["MagicMarquee", LazyMagicMarquee],
["MagicMenuChannel", LazyMagicMenuChannel],
["MagicMenuContent", LazyMagicMenuContent],
["MagicMenuFloat", LazyMagicMenuFloat],
["MagicMenuItem", LazyMagicMenuItem],
["MagicMenuProvider", LazyMagicMenuProvider],
["MagicMenuRemote", LazyMagicMenuRemote],
["MagicMenuTrigger", LazyMagicMenuTrigger],
["MagicMenuView", LazyMagicMenuView],
["MagicModal", LazyMagicModal],
["MagicPlayerAudio", LazyMagicPlayerAudio],
["MagicPlayerAudioControls", LazyMagicPlayerAudioControls],
["MagicPlayerDisplayTime", LazyMagicPlayerDisplayTime],
["MagicPlayerMuxPopover", LazyMagicPlayerMuxPopover],
["MagicPlayerOverlay", LazyMagicPlayerOverlay],
["MagicPlayerPoster", LazyMagicPlayerPoster],
["MagicPlayerProvider", LazyMagicPlayerProvider],
["MagicPlayerTimeline", LazyMagicPlayerTimeline],
["MagicPlayerVideo", LazyMagicPlayerVideo],
["MagicPlayerVideoControls", LazyMagicPlayerVideoControls],
["MagicScrollCollision", LazyMagicScrollCollision],
["MagicScrollMotion", LazyMagicScrollMotion],
["MagicScrollProvider", LazyMagicScrollProvider],
["MagicScrollScene", LazyMagicScrollScene],
["MagicDraggable", LazyMagicDraggable],
["MagicNoise", LazyMagicNoise],
["MagicToastProvider", LazyMagicToastProvider],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
